'use client';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import NextErrorComponent from 'next/error';

export default function GlobalError({
  error,
  reset,
  statusCode,
}: {
  error: Error & { digest?: string };
  reset: () => void;
  statusCode: number;
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  return <NextErrorComponent statusCode={statusCode} />;
}
